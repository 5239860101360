import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import MatchMedia from '../../ui/MatchMedia/MatchMedia';
import { LinearPodDesktop } from './Desktop/LinearPodDesktop';
import { LinearPodMobile } from './Mobile/LinearPodMobile';

const LinearPod = ({
  product,
  labels,
  layout,
  lazyLoadImage,
  addProductToCart,
  isAddToCartInProgress,
  isSelected,
  buttonLabel,
  clickForProductDetails,
  isPodActionButtonEnabled,
  appCtx,
  sponsoredClick,
  handlePodClick,
  isFirstPod,
  redirectToPDP,
  setIsAddToCartInProgress,
  index,
}) => {
  const renderPod = (CMP) => {
    return (
      <CMP
        product={product}
        labels={labels}
        layout={layout}
        lazyLoadImage={lazyLoadImage}
        addProductToCart={addProductToCart}
        isAddToCartInProgress={isAddToCartInProgress}
        isSelected={isSelected}
        buttonLabel={buttonLabel}
        clickForProductDetails={clickForProductDetails}
        isPodActionButtonEnabled={isPodActionButtonEnabled}
        appCtx={appCtx}
        sponsoredClick={sponsoredClick}
        handlePodClick={handlePodClick}
        isFirstPod={isFirstPod}
        redirectToPDP={redirectToPDP}
        setIsAddToCartInProgress={setIsAddToCartInProgress}
        index={index}
      />
    );
  };
  return (
    <MatchMedia
      content={{
        mobile: () => renderPod(LinearPodMobile),
        tablet: () => renderPod(LinearPodMobile),
        desktop: () => renderPod(LinearPodDesktop),
      }}
    />
  );
};

LinearPod.defaultProps = {
  product: {},
  layout: '4_GRID',
  lazyLoadImage: false,
  appCtx: {},
  isPodActionButtonEnabled: false,
  redirectToPDP: false,
};

LinearPod.propTypes = {
  product: PropTypes.object,
  appCtx: PropTypes.object,
  labels: PropTypes.object.isRequired,
  layout: PropTypes.string,
  lazyLoadImage: PropTypes.bool,
  addProductToCart: PropTypes.func.isRequired,
  isAddToCartInProgress: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  clickForProductDetails: PropTypes.func.isRequired,
  isPodActionButtonEnabled: PropTypes.bool,
  sponsoredClick: PropTypes.func.isRequired,
  handlePodClick: PropTypes.func.isRequired,
  isFirstPod: PropTypes.bool.isRequired,
  redirectToPDP: PropTypes.bool,
  setIsAddToCartInProgress: PropTypes.object.isRequired,
  index: PropTypes.object.isRequired,
};

export { LinearPod };
export default withLabels(LinearPod);
