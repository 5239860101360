import { isTottusStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withConfig } from '../../../../utils/ConfigurationContext';
import { withLabels } from '../../../../utils/LabelsContext';
import _ from '../../../../utils/LodashImports';
import { redirect } from '../../../../utils/RouteHandler';
import noop from '../../../../utils/noop';
import useIntersection from '../../../../utils/useIntersection';
import Badges from '../../../Badges/Badges';
import BestSellerRibbon from '../../../BestSellerRibbon/BestSellerRibbon';
import ImageGallery from '../../../ImageGallery/ImageGallery';
import MultipurposeBadge from '../../../MultipurposeBadge/MultipurposeBadge';
import MyLists from '../../../MyLists/MyLists';
import Prices from '../../../Prices/Prices';
import ProdFeatures from '../../../ProdFeatures/ProdFeatures';
import ShippingDetails from '../../../ShippingDetails/ShippingDetails';
import AddToCartButton from '../../../ui/AddToCartButton/AddToCartButton';
import BodyCopy from '../../../ui/BodyCopy/BodyCopy';
import Button from '../../../ui/Button/Button';
import ColorSwatch from '../../../ui/ColorSwatch/ColorSwatch';
import ImageGroup from '../../../ui/ImageGroup/ImageGroup';
import RatingsVisual from '../../../ui/RatingsVisual/RatingsVisual';
import Reviews from '../../../ui/Reviews/Reviews';
import MarketplaceSpinner from '../../../ui/Spinner/MarketplaceSpinner';
import Tab from '../../../ui/Tab/Tab';
import TruncateText from '../../../ui/TruncateText/TruncateText';
import {
  buildPodUrl,
  capitalizeText,
  formatUrl,
  getCallToActionText,
  getSelectedColorIndex,
  getSellerIconClass,
  getUrlWithSponsoredProductClickData,
} from '../../podUtils';
import LinearPodDesktopStyles from './LinearPodDesktop.style';

const LinearPodDesktop = ({
  product,
  labels,
  layout,
  lazyLoadImage,
  buttonLabel,
  clickForProductDetails,
  isAddToCartInProgress,
  isSelected,
  isPodActionButtonEnabled,
  appCtx,
  sponsoredClick,
  handlePodClick,
  isFirstPod,
  redirectToPDP,
  setIsAddToCartInProgress,
  index: productIndex,
  config,
}) => {
  const isMultipurposeBadgeApplicable = _.get(appCtx, 'siteConfig.toggles.isMultipurposeBadgeApplicable', false);
  const isMeatStickerApplicable = _.get(appCtx, 'siteConfig.toggles.isMeatStickerApplicable', false);
  const isImageGalleryEnabled = _.get(appCtx, 'siteConfig.toggles.isImageGalleryEnabled', false);
  const isImageGalleryEnabledForLinearView = _.get(
    appCtx,
    'siteConfig.toggles.isImageGalleryEnabledForLinearView',
    false
  );
  const { topSpecifications, url, skuId, badges, meatStickers } = product;
  const hasStickers = isMeatStickerApplicable && !!meatStickers;
  const multipurposeBadges = _.get(product, 'multipurposeBadges', []);
  const [variantId, setVariantId] = useState(skuId);
  const [productUrl, setProductURL] = useState(buildPodUrl(url, skuId));
  const [hoverState, setHoverState] = useState(false);
  const isSponsored = _.get(product, 'isSponsored', false);
  const sponsoredText = _.get(labels, 'SPONSORED_TEXT', 'Patrocinado');
  const podRef = useRef();
  const inViewport = useIntersection(podRef, '0px', '0.3', false);
  const isTottusSA = isTottusStandalone(appCtx.store);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [activeIndicatorIndex, setActiveIndicatorIndex] = useState(0);

  useEffect(() => {
    if (inViewport) {
      sponsoredClick('impression');
    }
  }, [inViewport]);

  const productHasMediaUrls = product.mediaUrls && product.mediaUrls.length > 0;
  const [hasMediaUrls, setHasMediaUrls] = useState(productHasMediaUrls);
  const productImages = hasMediaUrls ? [product.mediaUrls[0]] : [product.media.id];

  const { onImageHover } = product.media;
  if (onImageHover === 'FETCH_SECOND_IMAGE') {
    if (hasMediaUrls) {
      const onHoverImageIndex = product.mediaUrls.length > 1 ? 1 : 0;
      productImages.push(product.mediaUrls[onHoverImageIndex]);
    } else {
      productImages.push(`${product.media.id}_2`);
    }
  }
  const [finalProductImages, setFinalProductImages] = useState(productImages);
  const [colorIndex, setColorIndex] = useState(getSelectedColorIndex(product));

  const [callToAction, setCallToAction] = useState(getCallToActionText(product));

  useEffect(() => {
    const currentProductUrl = buildPodUrl(product.url, product.skuId);

    if (product.isSponsored) {
      const productUrlWithClickData = getUrlWithSponsoredProductClickData(product, productIndex, config, appCtx);

      setProductURL(productUrlWithClickData);
    } else {
      setProductURL(currentProductUrl);
    }
  }, []);

  const handleClick = (setPodClickDdOnXlpOnly = false) => {
    sponsoredClick('visit');
    sponsoredClick('click');
    handlePodClick(setPodClickDdOnXlpOnly);
  };

  const handleColorSwatch = (obj, index) => {
    handleClick(true);
    setVariantId(obj.extraInfo);
    const { mediaUrls } = obj;
    const hasMediaUrlsAvailable = mediaUrls.length > 0;
    const images = hasMediaUrlsAvailable ? [mediaUrls[0]] : [obj.mediaId];
    if (onImageHover === 'FETCH_SECOND_IMAGE') {
      if (hasMediaUrlsAvailable) {
        const selectedImageIndex = mediaUrls.length > 1 ? 1 : 0;
        images.push(mediaUrls[selectedImageIndex]);
      } else {
        images.push(`${obj.mediaId}_2`);
      }
    }
    const finalProductUrl = buildPodUrl(obj.url);
    setProductURL(finalProductUrl);
    setHasMediaUrls(hasMediaUrlsAvailable);
    setFinalProductImages(images);
    setColorIndex(index);
    setActiveImageIndex(0);
  };

  const [activeTab, setActiveTab] = useState({ id: '', content: noop });

  const changeCallToAction = (tab, index) => {
    handleClick(true);
    setActiveTab(tab);
    return tab.id === 'Plan' && index === 1 ? setCallToAction('VIEW_PLAN') : setCallToAction('VIEW_PREPAID');
  };

  const renderTitle = (prod) => {
    return (
      <div>
        <BodyCopy size="title7" isHighlighted type="secondary" id={`testId-pod-brandName-${prod.productId}`}>
          {(cn) => <b className={`${cn} pod-title title-rebrand`}>{prod.brand}</b>}
        </BodyCopy>
        <style jsx>{LinearPodDesktopStyles}</style>
      </div>
    );
  };

  const renderSubTitle = (prod) => {
    return (
      <>
        <BodyCopy maxlines={3} size="title2" isHighlighted id={`testId-pod-displayName-${prod.productId}`}>
          {(cn) => (
            <b id={`testId-pod-displaySubTitle-${prod.productId}`} className={`${cn} pod-subTitle subTitle-rebrand`}>
              {prod.displayName}
            </b>
          )}
        </BodyCopy>
        <style jsx>{LinearPodDesktopStyles}</style>
      </>
    );
  };

  const renderSellerText = (prod) => {
    const sellerClass = getSellerIconClass(prod.sellerName || '');
    return (
      <TruncateText>
        <BodyCopy size="title4" id={`testId-pod-sellerName-${prod.productId}`}>
          {(cn) => (
            <b
              id={`testId-pod-displaySellerText-${prod.productId}`}
              className={`${cn} pod-sellerText seller-text-rebrand`}
            >
              {prod.sellerName ? `Por ${capitalizeText(prod.sellerName)}` : ''}
            </b>
          )}
        </BodyCopy>
        {sellerClass && <span id="seller-icon" className={`${sellerClass}Icon seller-icon`} />}
        <style jsx>{LinearPodDesktopStyles}</style>
      </TruncateText>
    );
  };

  const finalProductUrl = buildPodUrl(product.url, variantId);
  return (
    <a
      href={`${formatUrl(callToAction, productUrl)}`}
      className="pod pod-link"
      data-pod="catalyst-pod"
      data-key={product.productId}
      data-category={product.merchantCategoryId}
      data-gsccategory={product.GSCCategoryId}
      data-sponsored={product.isSponsored}
      id={`testId-pod-${variantId}`}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      role="button"
      tabIndex={0}
      onClick={() => handleClick()}
      ref={podRef}
    >
      {product.isBestSeller && <BestSellerRibbon />}
      <div className="section-head">
        {isImageGalleryEnabled && isImageGalleryEnabledForLinearView ? (
          <ImageGallery
            activeImageIndex={activeImageIndex}
            images={finalProductImages}
            activeIndicatorIndex={activeIndicatorIndex}
            setActiveImageIndex={setActiveImageIndex}
            setActiveIndicatorIndex={setActiveIndicatorIndex}
            lazyLoad={lazyLoadImage}
            className="list-view"
            width={170}
            height={170}
            alt={`${product.brand} - ${product.displayName}`}
            isFirstPod={isFirstPod}
            hideLazyLoadPlaceHolder={false}
          />
        ) : (
          <ImageGroup
            layout={layout}
            ids={finalProductImages}
            lazyLoad={lazyLoadImage}
            mouseOver={hoverState}
            width={170}
            height={170}
            className="list-view"
            alt={`${product.brand} - ${product.displayName}`}
            hasMediaUrls={hasMediaUrls}
            isFirstPod={isFirstPod}
          />
        )}
        {product.variants.map((variant) => {
          return (
            variant.type === 'COLOR' && (
              <ColorSwatch
                colorVariants={variant.options}
                handleColorSwatch={handleColorSwatch}
                selectedColorIndex={colorIndex}
                productDisplayLink={finalProductUrl}
                productId={product.productId}
                key={product.productId}
                maximum={5}
              />
            )
          );
        })}
      </div>
      <div className="link-container">
        <MyLists
          className="xlp-container one-column"
          appCtx={appCtx}
          product={{
            productId: product.productId,
            variantId: product.skuId,
            offeringId: product.offeringId,
          }}
          handleClick={handleClick}
        />
        <div className="section-body">
          <div className="pod-details section-body--left">
            <div className="pod-title-wrapper">
              {renderTitle(product)}
              {renderSubTitle(product)}
              {renderSellerText(product)}
            </div>
            {product.totalReviews && product.totalReviews > 0 && (
              <div className="pod-rating">
                <RatingsVisual value={Number(product.rating)} productId={product.productId} />
                <Reviews value={Number(product.totalReviews)} />
              </div>
            )}

            <div>
              <ProdFeatures features={topSpecifications} />
            </div>
          </div>
          <div className="section-body--right">
            <Badges
              badgeItems={hasStickers ? meatStickers : badges}
              deviceType="desktop"
              layout={layout}
              isSticker={hasStickers}
            />
            {product.viewTemplate === 'CONNECT' && (
              <Tab changeCallToAction={changeCallToAction} productId={product.productId} />
            )}
            {callToAction === 'VIEW_PLAN' ? (
              <div className="tabContent">
                <BodyCopy size="copy3">{activeTab.content()}</BodyCopy>
              </div>
            ) : (
              <Prices
                priceList={product.prices}
                productId={product.productId}
                page="PLP"
                variant="DESKTOP_LIST"
                discountBadge={product.discountBadge}
              />
            )}
            {product.availability ? (
              <ShippingDetails
                availability={product.availability}
                labels={labels}
                deviceType="desktop"
                layout={layout}
              />
            ) : null}
            {isMultipurposeBadgeApplicable && multipurposeBadges.length > 0 && (
              <div className="multipurpose-badge">
                <MultipurposeBadge badgeItems={multipurposeBadges} layout={layout} />
              </div>
            )}
            {isTottusSA && (
              <AddToCartButton
                addToCart={clickForProductDetails}
                colorIndex={colorIndex}
                isAddToCartInProgress={isAddToCartInProgress}
                product={product}
                setIsAddToCartInProgress={setIsAddToCartInProgress}
              />
            )}
            {!isTottusSA && isPodActionButtonEnabled && (
              <div className="pod-action pod-action-rebranding">
                <Button
                  id={`testId-Pod-action-${product.productId}`}
                  type="mkp-primary-medium primary-theme"
                  disabled={!product.prices || product.prices.length === 0 || (isAddToCartInProgress && !isSelected)}
                  onClick={(event) => {
                    if (product.viewTemplate !== 'CONNECT') {
                      event.stopPropagation();
                      handleClick(true);
                    } else {
                      handleClick();
                    }
                    if (isAddToCartInProgress) {
                      return event.preventDefault();
                    }
                    if (!(product.viewTemplate === 'CONNECT' || redirectToPDP)) {
                      event.preventDefault();
                    }
                    return product.viewTemplate === 'CONNECT' || redirectToPDP
                      ? redirect(formatUrl(callToAction, productUrl))
                      : clickForProductDetails(colorIndex);
                  }}
                >
                  <div className="pod-button-add-to-cart">
                    {isAddToCartInProgress && isSelected && (
                      <div className="pod-button-add-to-cart-spinner">
                        <MarketplaceSpinner size="small" color="primary" />
                      </div>
                    )}
                    <div>{callToAction === 'VIEW_PLAN' ? labels.VIEW_PLAN : buttonLabel}</div>
                  </div>
                </Button>
              </div>
            )}
            {isSponsored && (
              <div className="patrocinado-pod">
                <div className="patrocinado-title">{sponsoredText}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>{LinearPodDesktopStyles}</style>
    </a>
  );
};

LinearPodDesktop.defaultProps = {
  product: {},
  appCtx: {},
  config: {},
  lazyLoadImage: false,
  redirectToPDP: false,
};

LinearPodDesktop.propTypes = {
  product: PropTypes.object,
  appCtx: PropTypes.object,
  config: PropTypes.object,
  labels: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired,
  lazyLoadImage: PropTypes.bool,
  buttonLabel: PropTypes.string.isRequired,
  clickForProductDetails: PropTypes.func.isRequired,
  isAddToCartInProgress: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isPodActionButtonEnabled: PropTypes.bool.isRequired,
  sponsoredClick: PropTypes.func.isRequired,
  handlePodClick: PropTypes.func.isRequired,
  isFirstPod: PropTypes.bool.isRequired,
  redirectToPDP: PropTypes.bool,
  setIsAddToCartInProgress: PropTypes.object.isRequired,
  index: PropTypes.object.isRequired,
};

export { LinearPodDesktop };
export default withLabels(withConfig(LinearPodDesktop));
