import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const SliderDotsStyles = css`
  .dot-container {
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    border-radius: 8px;
    background-color: #333333cc;
    padding: 4px 4px;
    right: 1%;
    bottom: 12px;
    height: 10px;
    right: 8px;
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
      display: inline-block;
      background-color: ${colors.gray.shade33};
      &.active {
        width: 7px;
        height: 7px;
        background-color: ${colors.black.shade12};
      }
    }
  }
`;

export { SliderDotsStyles };
