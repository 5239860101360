/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import ImageGalleryDesktop from './ImageGalleryDesktop/ImageGalleryDesktop';
import ImageGalleryMobile from './ImageGalleryMobile/ImageGalleryMobile';

const ImageGallery = (props) => {
  return (
    <MatchMedia
      content={{
        mobile: () => <ImageGalleryMobile {...props} />,
        tablet: () => <ImageGalleryMobile {...props} />,
        desktop: () => <ImageGalleryDesktop {...props} />,
      }}
    />
  );
};

export default ImageGallery;
