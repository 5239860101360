import css from 'styled-jsx/css';

const ImageGalleryDesktopStyles = css`
  div {
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }

  section {
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .grid-view {
    max-height: 496px;
    @mixin tabletToDesktop {
      max-height: 248px;
    }
  }
  section > :global(picture > img) {
    display: block;
  }
  section > :global(img) {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
    display: block;
  }
  section > :global(picture) {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
    display: block;
  }
  section.image--active > :global(img) {
    transform: scale(1.05);
  }
  section.image--active > :global(picture) {
    transform: scale(1.05);
  }
  section > :global(img):nth-child(2) {
    display: none;
  }
  section > :global(picture):nth-child(2) {
    display: none;
  }
`;

const PreloadImageStyles = css`
  .hidden {
    display: none;
  }
`;

export { ImageGalleryDesktopStyles, PreloadImageStyles };
