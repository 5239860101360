import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import noop from '../../../utils/noop';
import { BackgroundOverlayStyles } from './BackgroundOverlay.style';

const BackgroundOverlay = ({ shade }) => {
  const overlayRef = useRef();
  if (typeof window === 'object') {
    return createPortal(
      <div
        ref={overlayRef}
        onKeyDown={noop}
        tabIndex="0"
        role="button"
        className={classNames(`background-overlay`, {
          [`shade-${shade}`]: shade && typeof shade === 'string',
        })}
      >
        <style jsx>{BackgroundOverlayStyles}</style>
      </div>,
      document.body
    );
  }
  return null;
};

BackgroundOverlay.defaultProps = {
  shade: null,
};

BackgroundOverlay.propTypes = {
  shade: PropTypes.oneOf(['lighter']),
};

export default BackgroundOverlay;
