import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { ImageGalleryControlButtonsStyles } from './ImageGalleryControlButtons.style';

const ImageGalleryControlButtons = ({ onPreviousHeadLine, onNextHeadline }) => {
  return (
    <div>
      <button
        id="testId-ImageGalleryControlButton-left"
        type="button"
        className="headline-control left"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onPreviousHeadLine();
        }}
      />

      <button
        id="testId-ImageGalleryControlButton-right"
        type="button"
        className="headline-control right"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onNextHeadline();
        }}
      />
      <style jsx>{ImageGalleryControlButtonsStyles}</style>
    </div>
  );
};

ImageGalleryControlButtons.defaultProps = {};

ImageGalleryControlButtons.propTypes = {
  onPreviousHeadLine: PropTypes.func.isRequired,
  onNextHeadline: PropTypes.func.isRequired,
};

export default withApplicationContext(ImageGalleryControlButtons);
