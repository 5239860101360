const IMAGE_GALLERY = {
  DEFAULT_IMAGE: 'default',
};

const getMediaData = (media) => {
  let mediaId = '';
  let mediaUrl = '';
  if (!media) {
    mediaId = IMAGE_GALLERY.DEFAULT_IMAGE;
  } else {
    mediaId = media.id || media;
    mediaUrl = media;
  }
  return { mediaId, mediaUrl };
};

export { IMAGE_GALLERY, getMediaData };
