import getConfig from 'next/config';
import apiConfig from '../config/api/apiConfig';
import constants from '../config/constants';
import _ from '../utils/LodashImports';
import { decodeAndFormatSearchTerm, getPageType, getSignal, getStore, makeRequest } from '../utils/SponsoredHelper';
import WebStorage from '../utils/WebStorage';

const nextConfig = getConfig();
const publicRuntimeConfig = (nextConfig && nextConfig.publicRuntimeConfig) || {};

const sponsoredUrlBase = publicRuntimeConfig.SPONSORED_ENDPOINT;
const { PRODUCTS } = constants.AD_TYPES_FOR_VAS;

const getCookieMabayaSession = () => {
  const cookie = WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true });

  if (cookie) {
    return cookie;
  }

  const mabayaSessionCookie = window.btoa(Date.now());
  WebStorage.setItem({
    name: 'mabayaSession',
    value: mabayaSessionCookie,
    shouldPersist: true,
  });

  return mabayaSessionCookie;
};

const getSponsoredClickServiceData = ({ adInfo, mPos, enabled, categoryName, platform, regionCode, store, adType }) => {
  if (`${enabled}` === 'false') return undefined;

  const cookie = getCookieMabayaSession();
  const vasStore = getStore(store);
  const data = {
    isXLP: true,
    pageType: getPageType(),
    adType: adType || PRODUCTS,
    platform,
    regionCode,
    context: {
      userIdentifier: cookie,
      adIdentifier: adInfo,
      ...(mPos !== undefined && { slot: mPos }),
      signal: getSignal(categoryName),
      ...(vasStore && { store: vasStore }),
    },
  };

  return data;
};

const sponsoredClickService = async ({ adInfo, mPos, enabled, categoryName, platform, tenant, store, adType }) => {
  if (`${enabled}` === 'false') return;
  const cookie = WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true }) || '';
  const vasStore = getStore(store);
  const data = {
    pageType: getPageType(),
    adType: adType || PRODUCTS,
    context: {
      userIdentifier: cookie,
      adIdentifier: adInfo,
      ...(mPos !== undefined && { slot: mPos }),
      signal: getSignal(categoryName),
      ...(vasStore && { store: vasStore }),
    },
  };

  const url = apiConfig.sponsoredClickUrl(sponsoredUrlBase);
  await makeRequest(url, data, platform, tenant);
};

const sponsoredImpressionService = async ({ platform, adInfo, mPos, enabled, tenant }) => {
  if (`${enabled}` === 'false') return;

  const cookie = WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true }) || '';

  const data = {
    context: {
      ...(cookie && { userIdentifier: cookie }),
      adIdentifier: adInfo,
      ...(mPos !== undefined && { slot: mPos }),
    },
  };

  const url = apiConfig.sponsoredImpressionUrl(sponsoredUrlBase);
  await makeRequest(url, data, platform, tenant);
};

const sponsoredVisitService = async (
  { referrerUrl = '', sku, enabled, isPDP = false, platform, tenant, categoryId },
  appCtx
) => {
  if (`${enabled}` === 'false') return;

  const isSLP = !!referrerUrl.split('Ntt=')[1];
  const isEnabledSposoredVisitInPLP = _.get(appCtx, 'siteConfig.toggles.isEnabledSposoredVisitInPLP', true);

  const referrerSignal = isSLP ? decodeAndFormatSearchTerm(referrerUrl) : categoryId;

  if ((!isSLP && !isEnabledSposoredVisitInPLP) || !referrerSignal) {
    return;
  }

  const cookie = (isPDP && WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true })) || '';

  const data = {
    context: {
      skuId: sku,
      searchTerm: referrerSignal,
      ...(cookie && { userIdentifier: cookie }),
    },
  };

  const url = apiConfig.sponsoredVisitUrl(sponsoredUrlBase);
  await makeRequest(url, data, platform, tenant);
};

export { getSponsoredClickServiceData, sponsoredClickService, sponsoredImpressionService, sponsoredVisitService };
