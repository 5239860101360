import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';
import { withProduct } from '../../../utils/ProductContext';
import ImageListing from '../../ui/ImageListing/ImageListing';
import { getMediaData } from '../ImageGalleryHelper';
import SliderDots from '../SliderDots/SliderDots';
import { ImageGalleryMobileStyles } from './ImageGalleryMobile.style';

const ImageGalleryMobile = ({
  activeImageIndex,
  images,
  setActiveImageIndex,
  lazyLoad,
  height,
  width,
  alt,
  isFirstPod,
  hideLazyLoadPlaceHolder,
  appCtx,
}) => {
  const carouselRef = useRef();
  const containerRef = useRef();
  // const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [xOffset, setXOffset] = useState(0);
  const [startX, setStartX] = useState();
  const isImageGallerySilderEnabled = _.get(appCtx, 'siteConfig.toggles.isImageGallerySilderEnabled', false);
  const maxImagesForGallery = _.get(appCtx, 'siteConfig.toggles.maxImagesForGallery', 5);
  const galleryImages = images.slice(1, maxImagesForGallery + 1);

  useEffect(() => {
    let { clientWidth } = carouselRef.current;
    const elementWidth = carouselRef.current?.getBoundingClientRect()?.width;
    if (elementWidth) {
      clientWidth = elementWidth;
    }
    setXOffset(-(activeImageIndex * clientWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImageIndex]);

  const handleTouchStart = (e) => {
    const { targetTouches } = e;

    if (targetTouches.length === 1) {
      const { clientX } = e.targetTouches[0];

      setStartX(clientX - xOffset);
    }
  };

  const handleTouchEnd = () => {
    setStartX(xOffset);

    const { offsetLeft } = carouselRef.current.children[activeImageIndex];

    const delta = Math.abs(xOffset) - offsetLeft;

    if (Math.abs(delta) < 15) {
      setActiveImageIndex(activeImageIndex);
    } else if (Math.abs(xOffset) < offsetLeft) {
      setActiveImageIndex(activeImageIndex - 1);
    } else {
      setActiveImageIndex(activeImageIndex + 1);
    }
  };

  const handleTouchMove = (e) => {
    const { clientWidth, scrollWidth } = carouselRef.current;
    const { targetTouches } = e;

    const { clientX } = targetTouches[0];

    if (targetTouches.length === 1) {
      const offset = clientX - startX;
      if (offset <= 0 && offset >= clientWidth - scrollWidth) {
        setXOffset(offset);
      }
    }
  };

  // const onGestureRef = useRef((e) => {
  //   e.preventDefault();
  // });

  // useEffect(() => {
  //   containerRef.current.removeEventListener('touchstart', onGestureRef.current);
  //   containerRef.current.addEventListener('touchstart', onGestureRef.current);
  // }, []);

  // const onTransitionEnd = (e) => {};

  const renderImages = (image, index, arr) => {
    const { mediaId, mediaUrl } = getMediaData(image);
    let key = mediaId;

    if (index === 0) {
      key = `${image}-hidden-start`;
    } else if (index === arr.length - 1) {
      key = `${image}-hidden-end`;
    }

    return (
      <div key={key} className="image-headline">
        <ImageListing
          id={mediaId}
          width={width}
          height={height}
          alt={alt}
          lazyLoad={lazyLoad}
          imageUrl={mediaUrl}
          hideLazyLoadPlaceHolder={hideLazyLoadPlaceHolder}
          priorityFetch={!!isFirstPod}
        />
        <style jsx>{ImageGalleryMobileStyles}</style>
      </div>
    );
  };

  return (
    <div
      className="headline-wrapper"
      onFocus={noop}
      ref={(r) => {
        containerRef.current = r;
      }}
      role="button"
      onKeyDown={noop}
      tabIndex="0"
    >
      <div
        className="image-slider"
        onTouchEnd={handleTouchEnd}
        // onTransitionEnd={onTransitionEnd}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
      >
        <div
          className="image-wrapper"
          ref={(r) => {
            carouselRef.current = r;
          }}
          style={{
            transform: `translateX(${xOffset}px)`,
            transition: `all 0.2s ease-in-out`,
          }}
          role="button"
          tabIndex="0"
          onKeyPress={noop}
          onClick={(e) => {
            e.stopPropagation();
            return true;
          }}
        >
          {galleryImages.map(renderImages)}
        </div>
      </div>
      {isImageGallerySilderEnabled && galleryImages.length > 1 && (
        <SliderDots
          allDots={[...Array(galleryImages.length).keys()]}
          maxDots={5}
          selectedIndex={activeImageIndex}
          onDotClick={(newIndex) => setActiveImageIndex(newIndex + 1)}
        />
      )}
      <style jsx>{ImageGalleryMobileStyles}</style>
    </div>
  );
};

ImageGalleryMobile.defaultProps = {
  images: [],
  lazyLoad: true,
  width: 249,
  height: 249,
  alt: 'preview',
  hideLazyLoadPlaceHolder: false,
  appCtx: {},
};

ImageGalleryMobile.propTypes = {
  images: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.number,
  alt: PropTypes.string,
  hideLazyLoadPlaceHolder: PropTypes.bool,
  isFirstPod: PropTypes.bool.isRequired,
  lazyLoad: PropTypes.bool,
  activeImageIndex: PropTypes.number.isRequired,
  setActiveImageIndex: PropTypes.func.isRequired,
  appCtx: PropTypes.object,
};

export default withApplicationContext(withProduct(ImageGalleryMobile));
