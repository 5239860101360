import css from 'styled-jsx/css';
import { icons } from '../../../config/styles';
import colors from '../../../config/styles/colors';
import zIndex from '../../../config/styles/zIndex';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const ImageGalleryControlButtonsStyles = css`
  .headline-control {
    position: absolute;
    top: 50%;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: transparent;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: ${zIndex.imageGallery.button};
    outline: none;

    &.left {
      left: 8px;
      background-image: url('${svgToDataUri(icons.leftSlide)}');
    }

    &.right {
      right: 8px;
      background-image: url('${svgToDataUri(icons.rightSlide)}');
    }
    &.connect-button {
      height: 29px;
      width: 15px;
    }

    &.opaque {
      background-color: ${colors.black.shade6};
    }
  }

  .icon {
    color: ${colors.gray.shade34};

    &.i-gallery.csicon-arrow_right {
      margin-left: 0;
    }
  }
`;

export { ImageGalleryControlButtonsStyles };
