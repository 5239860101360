import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { getImages } from '../../../utils/GetImages';
import _ from '../../../utils/LodashImports';
import { imageUrl as imageURLMaker } from '../../../utils/staticUrls';
import ImageStyles from './ImageListing.style';

const ImageListing = ({
  id,
  width,
  height,
  alt,
  lazyLoad,
  appCtx,
  quality,
  useBasePath, // TOBE Deprecated
  useSrcSet,
  crop,
  hideLazyLoadPlaceHolder,
  inlineDimensions,
  imageUrl,
  priorityFetch,
}) => {
  const { regionCode, store } = appCtx;
  const isCFMediasEnabled = _.get(appCtx, 'siteConfig.toggles.isCFMediasEnabled', false);
  const isPictureTagEnabled = _.get(appCtx, 'siteConfig.toggles.isPictureTagEnabled', false);

  const isImageDeliveryOverrideEnabled = _.get(appCtx, 'siteConfig.toggles.isImageDeliveryOverrideEnabled', false);

  const variantId = id.includes('/') ? id.split('/')[1] : id;

  const imageSrc = getImages({
    variantId: isCFMediasEnabled ? 'NoImage' : variantId,
    regionCode,
    useBasePath,
    attr: {
      width,
      height,
      crop,
      quality,
    },
    url: imageUrl,
    isPictureTagEnabled,
    isImageDeliveryOverrideEnabled,
    store,
  });

  const imageSrcSet = useSrcSet
    ? `${getImages({
        variantId: isCFMediasEnabled ? 'NoImage' : variantId,
        regionCode,
        useBasePath,
        attr: {
          width: width * 2,
          height: height * 2,
          crop,
          quality,
        },
        url: imageUrl,
        isPictureTagEnabled,
        isImageDeliveryOverrideEnabled,
        store,
      })}`
    : '';

  const [src, setSrc] = useState(imageSrc);
  const [srcSet, setSrcSet] = useState(imageSrcSet);

  useEffect(() => {
    setSrc(imageSrc);
    setSrcSet(imageSrcSet);
  }, [variantId, imageUrl]);

  const addDefaultSrc = () => {
    setSrcSet('');
    setSrc(imageURLMaker('defaultImage.jpg'));
  };

  const getImage = () => {
    return (
      <>
        {isPictureTagEnabled ? (
          <picture>
            <source srcSet={`${src}, ${srcSet} 2x`} type="image/webp,image/*,*/*" />
            <img
              src={src}
              srcSet={`${srcSet}${useSrcSet ? ' 2x' : ''}`}
              alt={alt}
              id={`testId-pod-image-${id}`}
              onError={addDefaultSrc}
              width={inlineDimensions ? width : undefined}
              height={inlineDimensions ? height : undefined}
              {...(priorityFetch ? { fetchpriority: 'high' } : {})}
            />
          </picture>
        ) : (
          <img
            src={src}
            srcSet={`${srcSet}${useSrcSet ? ' 2x' : ''}`}
            alt={alt}
            id={`testId-pod-image-${id}`}
            onError={addDefaultSrc}
            width={inlineDimensions ? width : undefined}
            height={inlineDimensions ? height : undefined}
            {...(priorityFetch ? { fetchpriority: 'high' } : {})}
          />
        )}
        <style jsx>{ImageStyles}</style>
      </>
    );
  };

  return lazyLoad ? (
    <LazyLoad
      placeholder={
        !hideLazyLoadPlaceHolder && (
          <div
            className="place-holder"
            style={{
              height,
            }}
          />
        )
      }
    >
      {getImage()}
    </LazyLoad>
  ) : (
    getImage()
  );
};

ImageListing.propTypes = {
  id: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string,
  lazyLoad: PropTypes.bool,
  appCtx: PropTypes.object.isRequired,
  quality: PropTypes.number,
  useBasePath: PropTypes.bool,
  useSrcSet: PropTypes.bool,
  crop: PropTypes.object,
  hideLazyLoadPlaceHolder: PropTypes.bool,
  inlineDimensions: PropTypes.bool,
  imageUrl: PropTypes.string,
  priorityFetch: PropTypes.bool.isRequired,
};

ImageListing.defaultProps = {
  alt: '',
  id: '',
  lazyLoad: false,
  quality: 70,
  useBasePath: false,
  useSrcSet: true,
  crop: undefined,
  hideLazyLoadPlaceHolder: false,
  inlineDimensions: true,
  imageUrl: '',
};

export default withApplicationContext(ImageListing);
export { ImageListing };
