import PropTypes from 'prop-types';
import React from 'react';
import { redirect } from '../../../utils/RouteHandler';
import colorSwatchGlobalStyles from './ColorSwatch.style';

const ColorSwatch = (props) => {
  const {
    colorVariants = [],
    handleColorSwatch,
    selectedColorIndex,
    productDisplayLink,
    maximum,
    productId,
    size,
    page,
    layout,
  } = props;
  const colorSwatchMarkup = colorVariants.length > maximum ? colorVariants.slice(0, maximum) : colorVariants;

  const getBackgroundCSS = (value) => {
    if (/^[0-9A-Fa-f]{6}$/i.test(value)) {
      return {
        backgroundColor: `#${value}`,
      };
    }
    if (value && value.indexOf('http') === 0) {
      return {
        backgroundImage: `url(${value})`,
        backgroundSize: 'cover',
      };
    }
    if (value && value.indexOf('http') === -1) {
      if (value.length < 10) {
        return {
          backgroundColor: value,
        };
      }
      const baseUrl = 'https://falabella.scene7.com';
      const finalValue = baseUrl.concat(`${value}`);
      return {
        backgroundImage: `url(${finalValue})`,
        backgroundSize: 'cover',
      };
    }
    return {
      backgroundColor: `undefined`,
    };
  };
  if (!colorSwatchMarkup.length) return null;
  return (
    <ul
      className={`swatch--container  
      ${size === 'small' && page !== 'PDP' ? 'fixed-height' : ''}
      swatch--container-${page} swatch--container-${layout}
      fa--color-swatch`}
    >
      {colorSwatchMarkup.map((options, index) => {
        return (
          <li
            className={index === selectedColorIndex ? `colorSwatch-li-selected-${size}` : `colorSwatch-li-${size}`}
            key={`swatch-${options.extraInfo}`}
          >
            <button
              className={`colorSwatch-${size}`}
              type="button"
              id={`testId-pod-colorSwatch-${productId}`}
              style={getBackgroundCSS(options.value)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleColorSwatch(
                  {
                    extraInfo: options.extraInfo,
                    mediaId: options.mediaId,
                    colorName: options.value,
                    url: options.url,
                    selected: options.selected,
                    label: options.label,
                    mediaUrls: options.mediaUrls || [],
                  },
                  index
                );
              }}
            />
          </li>
        );
      })}
      {colorVariants.length > maximum && (
        <li>
          <button
            type="button"
            className="colorSwatch-link"
            id={`testId-pod-colorSwatch-${productId}-redirectPDP`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              redirect(productDisplayLink);
            }}
          >
            <span className="csicon-add" />
          </button>
        </li>
      )}
      <style jsx>{colorSwatchGlobalStyles}</style>
    </ul>
  );
};

ColorSwatch.defaultProps = {
  maximum: Infinity,
  productDisplayLink: '',
  size: 'small',
  page: 'POD',
  layout: ' 4_GRID',
};

ColorSwatch.propTypes = {
  maximum: PropTypes.number,
  productDisplayLink: PropTypes.string,
  productId: PropTypes.string.isRequired,
  colorVariants: PropTypes.array.isRequired,
  handleColorSwatch: PropTypes.func.isRequired,
  selectedColorIndex: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  page: PropTypes.oneOf(['PDP', 'POD']),
  layout: PropTypes.string,
};

export default ColorSwatch;
