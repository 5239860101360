import css from 'styled-jsx/css';

const ImageGalleryMobileStyles = css`
  .headline-wrapper {
    .image-slider {
      overflow: hidden;
      position: relative;
      touch-action: pan-y;
      user-select: none;
      -webkit-user-drag: none;
    }
    .image-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .image-headline {
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      display: grid;
    }
  }
`;

export { ImageGalleryMobileStyles };
